import React, { useState } from "react";
import musicFile from "./mog.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Desktop from "./Desktop";


import { Grid } from '@mui/material'


import useWindowSize from "react-use/lib/useWindowSize";

import Logo from './mog_banner.svg'

const Home = () => {

  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };

  const { width, height } = useWindowSize();

  return (
    <div className="mainContent">
      <div className="garden">
        {!smallScreen && (
          <div className="desk">
        
      
            <Desktop />
         


            <> 
        <Modal
          backdrop="static"
          show={showModal}
          centered
          dialogClassName="modal-fullscreen"  // Custom class for full-screen modal
          keyboard={false}
          onHide={() => {}}
        >

<div className='navbartops'> <br/> <br/>
   <Grid container spacing={2}>

       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
   
       <img src={Logo} className='startlogo'/>

       </Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'> 
      
        <a > <br/>
        
        <a class="elementor-button elementor-button-link elementor-size-sm"  onClick={() => {
                      handleYesClick();
                      setShowModal(false);
                    }}>
                    <span class="elementor-button-content-wrapper">
                          <span class="elementor-button-text">Continue</span>
                  </span>
                  </a>
        
        
        
        
        
        </a>    
               </Grid>


</Grid>
 </Grid>
        <br/> <br/>
    </div>
         
        </Modal>
      </>
          </div>
        )}

        {smallScreen && (
          <div className="mob">
    
           
      
    <Desktop />
            <> 
        <Modal
          backdrop="static"
          show={showModal}
          centered
          dialogClassName="modal-fullscreen"  // Custom class for full-screen modal
          keyboard={false}
          onHide={() => {}}
        >

<div className='navbartops'>
   <Grid container spacing={2}>
   <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
   <br/> <br/>  


</Grid>
<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

<img src={Logo} className='startlogo'/>

</Grid>
<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
<Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'> 

 <a > <br/>
 
 <a class="elementor-button elementor-button-link elementor-size-sm"  onClick={() => {
               handleYesClick();
               setShowModal(false);
             }}>
             <span class="elementor-button-content-wrapper">
                   <span class="elementor-button-text">Continue</span>
           </span>
           </a>
 
 
 
 
 
 </a>    
        </Grid>


</Grid>
 </Grid>
 <br/> <br/>   <br/> <br/><br/> <br/> <br/> <br/> <br/> <br/> 
    </div>
         
        </Modal>
      </>
          </div>
        )}
      </div>

    
    </div>
  );
};

export default Home;