import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Meme from './MemeMog.jsx';
import AOS from "aos";
import bila from './bila.png';
import Desktopthree from './Desktopthree.jsx';
import shah from './shah.png'; // Your image path
import gizmoz from './gizmoz.png'; // Your image path
import logo1 from './mouse.png'; // Your image path
import logo2 from './m1.png'; // Your image path
import logo3 from './m2.png'; // Your image path
import logo4 from './m3.png'; // Your image path
import logo5 from './m4.png'; // Your image path
import logo6 from './m5.png'; // Your image path
import logo7 from './m6.png'; // Your image path
import logo8 from './m7.png'; // Your image path
import logo9 from './m8.png'; // Your image path
import logo10 from './m10.png'; // Your image path
import logo11 from './m11.png'; // Your image path
import logo12 from './m12.png'; // Your image path
import logo13 from './m13.png'; // Your image path
import logo14 from './m14.png'; // Your image path
import logo15 from './m15.png'; // Your image path
import logo16 from './m16.png'; // Your image path
import logo17 from './m17.png'; // Your image path
import logo18 from './m18.png'; // Your image path
import logo19 from './m19.png'; // Your image path
import logo20 from './m20.png'; // Your image path
import logo21 from './m9.png'; // Your image path
import main from './main.jpeg'; // Your image path
import Confettiz from './Confetti.js';
import "aos/dist/aos.css";
import image from './ImageUploader.jsx'


AOS.init({
  duration: 2000
});

function Desktop() {
  const images = [shah,gizmoz,shah,gizmoz,logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20, logo21]; // Add more images as needed
  const handleButtonClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScPMZax-GpLpA-EyF_8B3sq9HIkT4IwuaAhKvQCuKt7zWogBQ/viewform', '_blank');
  };
  return (
    <div className='backofdesktop'>
      <Confettiz imageSources={images} count={15} />
      
      <h1 className='mog_top_text'> <img src={bila}  className='image_all'/> $MOG OF FAME <img src={bila}  className='image_all'/>  </h1>
      <p className='mog_top_text_under'>
        <span style={{ color: "#fff" }}>THE</span> $MOG <span style={{ color: "#fff" }}>MEME COLLECTION</span>
      </p>
      <br />
      <div className="containerz">
        <img src={main} className="image" />
      </div>   <br /><br />
      <Desktopthree/>
      <br /><br />
      <Meme />
     
     
      <br /><br />
      <h1 className='mog_top_text_ppt'>Upload Memes here </h1>
      <br /><br />
      <div className="center">
      <a className="elementor-button elementor-button-link elementor-size-sm" onClick={handleButtonClick}>
        <span className="elementor-button-text">Upload Memes</span>
      </a>
    </div>
      <br /><br />
      <h1 className='mog_top_text'> <span style={{ color: "#fff" }}>ONCE A</span> MOGGER <span style={{ color: "#fff" }}>ALWAYS A</span> MOGGER</h1>
      

    </div>
  );
}

export default Desktop;
