import React, { useEffect } from 'react';
import './Confetti.css';

const Confetti = ({ imageSources, count = 10 }) => {
  useEffect(() => {
    const confettiContainer = document.getElementById('confetti-container');

    for (let i = 0; i < count; i++) {
      const confettiElement = document.createElement('img');
      confettiElement.src = imageSources[Math.floor(Math.random() * imageSources.length)];
      confettiElement.classList.add('confetti');
      confettiElement.style.left = `${Math.random() * 100}vw`;
      confettiElement.style.animationDelay = `${Math.random() * 0.1}s`;
      confettiElement.style.width = `${Math.random() * 20 + 20}px`; // Random size between 10px and 20px
      confettiElement.style.animationDuration = `${Math.random() * 5 + 5}s`; // Random duration between 5s and 10s
      confettiContainer.appendChild(confettiElement);
    }

    return () => {
      while (confettiContainer.firstChild) {
        confettiContainer.removeChild(confettiContainer.firstChild);
      }
    };
  }, [imageSources, count]);

  return <div id="confetti-container" className="confetti-container"></div>;
};

export default Confetti;
