import React from 'react'
import Marquee from "react-fast-marquee";
function Desktopthree() {
  return (
    <div>
      
<Marquee className='first_marque'>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>////  &nbsp; &nbsp;  $MOG OF FAME &nbsp; &nbsp;    ////</h1> 
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
 <h1 className='p3'>//// &nbsp; &nbsp; $MOG OF FAME&nbsp; &nbsp; ////</h1>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;

</Marquee>


    </div>
  )
}

export default Desktopthree